import {
	Box,
	Button,
	Flex,
	HStack,
	IconButton,
	Image,
	Stack,
	Text,
	useColorMode,
	useColorModeValue
} from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'

import { DarkModeSwitch } from './DarkmodeSwitch'
import { Link } from '@chakra-ui/react'
import Logout from './Logout'
import { isMobile } from '../common/utils'
import { textFontSize } from './InputFields'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const Links = ['Profile', 'Staff', 'Payroll', "T&C's"]

const NavLink = ({ children, link, hoverProps, setIsOpen, bg }) => {
	const navigate = useNavigate()
	function onClick() {
		setIsOpen(false)
		navigate(link)
	}
	return (
		<Button
			fontSize={['12px', '15px']}
			px={2}
			py={1}
			rounded={'md'}
			bg={bg}
			_hover={{
				textDecoration: 'none',
				borderRadius: '5px',
				...hoverProps
			}}
			_pressed={
				{
					border: '1px solid lightgray'
				}
			}
			onClick={onClick}
			color="lightgray"
			href={link}
		>
			<Text fontSize={textFontSize} fontWeight="bold">
				{children}
			</Text>
		</Button>
	)
}

export default function Navbar() {
	const [isOpen, setIsOpen] = useState(false)
	const { colorMode } = useColorMode()
	const navigate = useNavigate()
	const isDark = colorMode === 'dark'
	return (
		<>
			<Box
				bg={useColorModeValue('light.navbar', 'dark.navbar')}
				w="100vw"
				position="absolute"
				zIndex="overlay"
			>
				<Flex
					h={16}
					alignItems={'center'}
					justifyContent={'space-between'}
					height={isMobile ? '45px' : '75px'}
					w={['95%', '33rem', '45rem']}
					mx="auto"
				>
					<IconButton
						size={'sm'}
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						aria-label={'Open Menu'}
						display={{
							md: 'none'
						}}
						onClick={() => setIsOpen(!isOpen)}
						width="30px"
						borderRadius="50%"
						boxShadow="1px 1px 1px 1px rgba(0,0,0,0.1)"
						ml="5px"

					/>
					{isOpen && (
						<Box
							mt={['15rem', '16rem']}
							w={['11rem', '11rem']}
							position="absolute"
							borderRadius="10px"
							bg={useColorModeValue('light.navbar', 'dark.navbar')}
							boxShadow="0px 0px 6px 4px rgba(0,0,0,0.15)"
						>
							<Stack as={'nav'} spacing={2} p="5px">
								{Links.map((link) => (
									<NavLink
										key={link}
										bg={useColorModeValue('light.navbar', 'dark.navbar')}
										link={link.toLowerCase()}
										setIsOpen={setIsOpen}
										hoverProps={
											{
												boxShadow: '0px 0px 1px 1px lightgray'
											}
										}
									>
										{link}
									</NavLink>
								))}
							</Stack>
						</Box>
					)}
					<Box class='homeImage' type="button" onClick={() => navigate('/profile')} _hover={{ cursor: 'pointer' }}>
						<Image
							src="light-logo.svg"
							alt="light_logo"
							w={["50px", '75px']}
							mt="2%"
							alignSelf="center"
						/>
					</Box>
					<HStack
						as={'nav'}
						spacing={4}
						display={{
							base: 'none',
							md: 'flex'
						}}
					>
						{Links.map((link) => (
							<NavLink
								key={link}
								bg={useColorModeValue('light.navbar', 'dark.navbar')}
								setIsOpen={setIsOpen}
								hoverProps={{
									boxShadow: '0px 0px 2px 2px lightgray',
								}}
								link={`/${link.toLowerCase()}`}
							>
								{link}
							</NavLink>
						))}
					</HStack>
					<Flex flexDirection={'row'} gap={'5px'} alignItems="center">
						<DarkModeSwitch />
						<Logout />
					</Flex>
				</Flex>
			</Box>
		</>
	)
}
