import { smallFontSize, textFontSize } from "../components/InputFields";

import Container from "../components/Container";
import PageContainer from "../components/PageContainer";
import { Text } from "@chakra-ui/react";

const TermsAndConditions = () => {
    document.title = 'Terms & Conditions'
    return (
        <PageContainer header="Terms & Conditions">
            <Container>
                <Text>
                    <Text fontSize={smallFontSize} mb={3}>
                        These Terms and Conditions ("Terms") govern the use of Payrollmetrix.com and its web-based app, app.payrollmetrix.com (collectively referred to as "the Service").

                        By accessing and using the Service, you ("the Client") agree to be bound by these Terms. If you do not agree with these Terms, you must refrain from using the Service.

                    </Text>
                    <Text fontSize={textFontSize} fontWeight="bold" my={2}>Service Description</Text>

                    <Text fontSize={smallFontSize} my={1}>
                        <b>1.1 -</b> Payrollmetrix.com provides the Client with a link to app.payrollmetrix.com, an app that offers  an automated payroll tool linked to a third-party payroll processor.
                    </Text>


                    <Text fontSize={smallFontSize} my={1}>
                        <b>1.2 -</b> The Service simplifies your payroll process by collecting staff hours and forwarding them to our nominated payroll provider. The payroll provider will handle all necessary legal processes for payroll.
                    </Text>

                    <Text fontSize={textFontSize} fontWeight="bold" my={2}>Payroll Services and Obligations</Text>

                    <Text fontSize={smallFontSize} my={1}>
                        <b>2.1 -</b> By using the Service, you acknowledge that employees will be required to register with our nominated payroll provider on a zero-hours contract. However, you, as the Client, retain all legal responsibilities for providing a safe and compliant working environment.
                    </Text>
                    <Text fontSize={smallFontSize} my={1}>
                        <b>2.2 -</b> If any transgressions of employment law come to our attention, our payroll services offered to you may be withdrawn.
                    </Text>
                    <Text fontSize={smallFontSize} my={1}>
                        <b>2.3 -</b> Payment by you, as the Client, is required to process the hours provided. Failure to make payment will result in the inability to process hours for the respective staff members. The payment deadline is 12:00 noon every Wednesday. Once payment is received, all payroll services will be provided by the nominated payroll provider by 12:00 noon on the Friday of the same week.
                    </Text>
                    <Text fontSize={textFontSize} fontWeight="bold" my={2}>Confidentiality and Security</Text>

                    <Text fontSize={smallFontSize} my={1}>
                        <b>3.1 -</b> During the use of the Service, you may have access to confidential information. You agree to treat all confidential information with the utmost care and not disclose it to any third party without prior written consent and inline with all General Data Protection Regulations (GDPR).
                    </Text>
                    <Text fontSize={textFontSize} fontWeight="bold" my={2}>Termination</Text>

                    <Text fontSize={smallFontSize} my={1}>
                        <b>4.1 -</b> You may terminate your use of the Service at any time by ceasing to access and use the Service.
                    </Text>
                    <Text fontSize={smallFontSize} my={1}>
                        <b>4.2 -</b> Payrollmetrix reserves the right to suspend or terminate your access to the Service at any time, without prior notice, for any reason deemed necessary.
                    </Text>

                    <Text fontSize={textFontSize} fontWeight="bold" my={2}>Governing Law and Jurisdiction</Text>

                    <Text fontSize={smallFontSize} my={1}>
                        <b>5.1 -</b> These Terms shall be governed by and construed in accordance with the laws of the United Kingdom.
                    </Text>
                    <Text fontSize={smallFontSize} my={1}>
                        <b>5.2 -</b>  Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of the United Kingdom.
                    </Text>

                    <Text fontSize={textFontSize} fontWeight="bold" my={2}>Metrix Brand Name</Text>

                    <Text fontSize={smallFontSize} my={1}>
                        <b>6.1 -</b> "Metrix" is the brand name used by Payrollmetrix to refer to "metrics" within the Service. The term "Metrix" is deemed to have the same meaning as "metrics" within these Terms and Conditions.
                    </Text>


                    <Text fontSize={smallFontSize} mt={3}>By using the Service, you indicate your agreement to these Terms and Conditions.
                        Please note that the Service is provided "as is" without warranties of any kind, and Payrollmetrix.com disclaims any liability for damages arising from the use of the Service.
                    </Text>
                </Text>
            </Container>
        </PageContainer >
    );
}

export default TermsAndConditions;