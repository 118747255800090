import { AppButton, smallFontSize } from '../../components/InputFields'
import {
	Box,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorModeValue
} from '@chakra-ui/react'
import { containerFontSize, textFontSize } from '../../components/InputFields'

import React from 'react'

const PaymentModal = ({ show, onClose, amount }) => {
	return (
		<Modal
			isOpen={show}
			onClose={onClose}
			size={window.innerWidth < 500 ? 'xs' : 'lg'}
		>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent
				bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
				color={useColorModeValue('black', 'dark.textColor')}
			>
				<ModalHeader>
					<Text mb="10px" fontSize={containerFontSize}>
						Payroll Confirmed!
					</Text>
					<Text fontSize={textFontSize}>
						Amount Due:
						<Text
							fontSize={containerFontSize}
							alignSelf={'end'}
							fontWeight="bold"
							display="inline-block"
							pl="5px"
						>
							{amount}
						</Text>
						<Text fontSize={smallFontSize} display="inline-block" pl="5px">
							(inc. VAT)
						</Text>
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody p={4}>
					<Text fontSize="15px">
						<Text fontSize="15px" fontWeight="bold">
							Disclaimer:
						</Text>
						To ensure your employees are paid on time, please complete payment
						to the account below before Wednesday at noon. This will ensure that
						your employees receive their wages on Friday of this week.
						<Text my="5px">
							Use of this app and it's payroll services constitutes your
							agreement to abide by our Terms and Conditions found{' '}
							<Link
								href="https://app.payrollmetrix.com/t&c's"
								style={{ textDecoration: 'underline' }}
							>
								here
							</Link>
						</Text>
						<Text my="5px">Thank you very much.</Text>
					</Text>
					<Box
						my="20px"
						borderColor={useColorModeValue('gray', 'black')}
						border="1px solid"
						borderRadius="10px"
						p="10px"
					>
						<Text fontSize="15px">
							Business Name:
							<Text
								fontSize="19px"
								fontWeight="bold"
								display="inline-block"
								pl="5px"
							>
								{process.env.REACT_APP_BUSINESS_NAME}
							</Text>
						</Text>
						<Text fontSize="15px">
							Account Number
							<Text
								fontSize="19px"
								fontWeight="bold"
								display="inline-block"
								pl="5px"
							>
								{process.env.REACT_APP_ACCOUNT_NUMBER}
							</Text>
						</Text>
						<Text fontSize="15px">
							Sort Code
							<Text
								fontSize="19px"
								fontWeight="bold"
								display="inline-block"
								pl="5px"
							>
								{process.env.REACT_APP_SORT_CODE}
							</Text>
						</Text>
					</Box>
				</ModalBody>
				<ModalFooter justifyContent="center" gap="20px">
					<AppButton onClick={onClose} value="Close" />
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default PaymentModal
