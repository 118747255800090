import {
	AppButton,
	AppText,
	ErrorMessage,
	InputField,
	textFontSize
} from '../components/InputFields'
import { Flex, HStack, Spinner, Text, useColorMode } from '@chakra-ui/react'
import React, { Suspense, useContext, useEffect, useState } from 'react'

import { AccountDataContext } from '../providers/AccountProvider'
import ChangePasswordModal from './Modals/ChangePasswodModal'
import Container from '../components/Container'
import DeleteModal from './Modals/DeleteModal'
import EditModal from './Modals/EditModal'
import EditProfileModal from './Modals/EditProfileModal'
import PageContainer from '../components/PageContainer'
import { StaffDataContext } from '../providers/StaffProvider'
import StaffTable from '../components/Table'
import { ellipsesTextProps } from '../common/utils'

const Profile = () => {
	const accessToken = localStorage.getItem('user-token')
	const { colorMode } = useColorMode()
	const isDark = colorMode === 'dark'

	const {
		isLoading,
		accountInfo,
		showEditProfileModal,
		setShowEditProfileModal
	} = useContext(AccountDataContext)

	const {
		showDeleteModal,
		showEditModal,
		setShowDeleteModal,
		setShowEditModal,
		staff
	} = useContext(StaffDataContext)

	const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)

	const isThereStaff = staff.length === 0
	document.title = 'Profile'

	const staffData = staff.map(
		({ first_name, last_name, email, hourly_rate, holiday_pay_balance }) => [
			<Text
				color={isDark ? 'white' : 'black'}
				textAlign={'center'}
				fontSize={textFontSize}
				maxWidth={['100px', '150px']}
				{...ellipsesTextProps}
			>
				{first_name}
			</Text>,
			<Text
				color={isDark ? 'white' : 'black'}
				textAlign={'center'}
				fontSize={textFontSize}
				maxWidth={['100px', '150px']}
				{...ellipsesTextProps}
			>
				{last_name}
			</Text>,
			<Text
				color={isDark ? 'white' : 'black'}
				textAlign={'center'}
				fontSize={textFontSize}
			>
				{email}
			</Text>,
			<Text
				color={isDark ? 'white' : 'black'}
				textAlign={'center'}
				fontSize={textFontSize}
			>
				£ {parseFloat(hourly_rate).toFixed(2)}
			</Text>,
			<Text
				color={isDark ? 'white' : 'black'}
				textAlign={'center'}
				fontSize={textFontSize}
			>
				£ {parseFloat(holiday_pay_balance).toFixed(2)}
			</Text>
		]
	)

	return (
		<PageContainer header="Profile">
			<Container header="My Details">
				<HStack mb={3}>
					<AppText value="Name:" fontWeight="bold" />
					{isLoading ? <Spinner /> : <AppText value={!isLoading ? accountInfo.name : ''} />}
				</HStack>
				<Flex flexDirection="row" gap={2} mb={3}>
					<AppText value="Email:" fontWeight="bold" />
					{isLoading ? <Spinner /> : <AppText value={!isLoading ? accountInfo.email : ''} />}
				</Flex>
				<Flex flexDirection="row" gap={2} mb={3}>
					<AppText value="Password:" fontWeight="bold" />
					{isLoading ? <Spinner /> : <AppText value="*********" />}
				</Flex>
				<Flex flexDirection="row" gap={2} mb={3}>
					<AppText value="Business:" fontWeight="bold" />
					{isLoading ? <Spinner /> : <AppText value={!isLoading ? accountInfo.business_name : ''} />}
				</Flex>
				<HStack justifyContent={'center'} spacing={7} mt={'15px'}>
					<AppButton
						onClick={() => setShowEditProfileModal(true)}
						value="Edit"
					/>
					<AppButton
						onClick={() => setOpenChangePasswordModal(true)}
						value="Change Password"
					/>
				</HStack>
			</Container>
			<Container header="Branch Staff">
				{isLoading ? <Spinner /> :
					isThereStaff ? (
						<AppText value="No staff work in this branch" />
					) : (
						<StaffTable
							headers={[
								'Firstname',
								'Surname',
								'Email',
								'Rate',
								'Holiday Remaining'
							]}
							rows={staffData}
						/>
					)}

				<HStack justifyContent={'center'} spacing={7} mt={'15px'}>
					<AppButton
						onClick={() => setShowEditModal(true)}
						value="Edit"
						disabled={isThereStaff}
					/>
					<AppButton
						onClick={() => setShowDeleteModal(true)}
						value="Delete"
						disabled={isThereStaff}
					/>
				</HStack>
			</Container>
			{showEditModal && (
				<EditModal
					staff={staff}
					show={showEditModal}
					onClose={() => setShowEditModal(false)}
					title="Edit / Add Staff"
					sub="Choose a member of staff to change their info about:"
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					staff={staff}
					show={showDeleteModal}
					onClose={() => setShowDeleteModal(false)}
					title="Delete Staff"
					sub="Choose a member of staff to delete:"
				/>
			)}
			{openChangePasswordModal && (
				<ChangePasswordModal
					show={openChangePasswordModal}
					onClose={() => setOpenChangePasswordModal(false)}
					token={accessToken}
				/>
			)}
			{showEditProfileModal && (
				<EditProfileModal
					show={showEditProfileModal}
					onClose={() => setShowEditProfileModal(false)}
				/>
			)}
		</PageContainer>
	)
}

export default Profile
