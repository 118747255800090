import { average, sum } from '../common/metrics'
import { createContext, useEffect, useState } from 'react'

import { getNWeeksMetricsHistory } from '../common/methods'

export const MetricsDataContext = createContext()

function processDates(dateList) {
	return dateList.map(({ week_ending_date: oldDate, ...rest }) => {
		var date = new Date(oldDate.substring(0, 10))
		var formattedData = date.toLocaleDateString('en-GB')
		return {
			...rest,
			week_ending_date: formattedData
		}
	})
}

export function MetricsDataProvider({ children }) {
	const [showAddMetricModal, setShowAddMetricModal] = useState(false)
	const [payrollHistory, setPayrollHistory] = useState([])
	const [utilitiesHistory, setUtilitiesHistory] = useState([])
	const [salesWetHistory, setSalesWetHistory] = useState([])
	const [salesDryHistory, setSalesDryHistory] = useState([])
	const [salesWetCostHistory, setSalesWetCostHistory] = useState([])
	const [salesDryCostHistory, setSalesDryCostHistory] = useState([])
	// const [isLoading, setIsLoading] = useState(true)

	const accessToken = localStorage.getItem('user-token')
	const weeks = 4

	const labels = payrollHistory.map(({ week_ending_date }) => {
		const chopped = week_ending_date.substring(0, week_ending_date.length - 5)
		return chopped
	})

	const payrollSum = sum(payrollHistory)

	const payrollAvg = average(payrollHistory)

	const salesWetSum = sum(salesWetHistory)

	const salesWetAvg = average(salesWetHistory)

	const salesDrySum = sum(salesDryHistory)

	const salesDryAvg = average(salesDryHistory)

	const utilitiesSum = sum(utilitiesHistory)

	const utilitiesAvg = average(utilitiesHistory)

	const totalCost = sum(salesDryCostHistory) + sum(salesWetCostHistory)

	const totalSales = salesWetSum + salesDrySum

	const grossProfit = salesDrySum + salesWetSum - totalCost

	const netProfit = grossProfit - (utilitiesSum + payrollSum)

	useEffect(() => {
		getNWeeksMetricsHistory({ weeks, token: accessToken }).then((res) => {
			const [
				{
					metrics: [{ values: salesWetInfo }, { values: salesDryInfo }]
				},
				{
					metrics: [{ values: salesWetCostInfo }, { values: salesDryCostInfo }]
				},
				{
					metrics: [{ values: payrollInfo }]
				},
				{
					metrics: [{ values: utilitiesInfo }]
				}
			] = res
			setSalesWetHistory(processDates(salesWetInfo))
			setSalesDryHistory(processDates(salesDryInfo))
			setSalesWetCostHistory(processDates(salesWetCostInfo))
			setSalesDryCostHistory(processDates(salesDryCostInfo))
			setPayrollHistory(processDates(payrollInfo))
			setUtilitiesHistory(processDates(utilitiesInfo))
		})
		// .finally(() => setIsLoading(false))
	}, [showAddMetricModal, accessToken])

	const salesHistoryDataset = [
		{
			label: 'Wet Sales',
			data: salesWetHistory.map((record) => record.value),
			borderColor: '#be2ed6',
			cubicInterpolationMode: 'monotone',
			pointRadius: 5,
			tension: 1
		},
		{
			label: 'Dry Sales',
			data: salesDryHistory.map((record) => record.value),
			borderColor: '#f1b04c',
			cubicInterpolationMode: 'monotone',
			pointRadius: 5,
			tension: 1
		}
	]

	const costHistoryDataset = [
		{
			label: 'Payroll',
			data: payrollHistory.map((record, index) => {
				const salesTotal =
					salesWetHistory[index].value + salesDryHistory[index].value

				return salesTotal === 0 ? 0 : (record.value / salesTotal) * 100
			}),
			borderColor: '#0086FF',
			cubicInterpolationMode: 'monotone',
			pointRadius: 5,
			tension: 1
		},
		{
			label: 'Utilities',
			data: utilitiesHistory.map((record, index) => {
				const salesTotal =
					salesWetHistory[index].value + salesDryHistory[index].value

				return salesTotal === 0 ? 0 : (record.value / salesTotal) * 100
			}),
			borderColor: '#1df566',
			cubicInterpolationMode: 'monotone',
			pointRadius: 5,
			tension: 1
		}
	]

	return (
		<MetricsDataContext.Provider
			value={{
				payrollAvg,
				payrollSum,
				utilitiesAvg,
				utilitiesSum,
				salesDrySum,
				salesWetSum,
				salesDryAvg,
				salesWetAvg,
				totalSales,
				totalCost,
				grossProfit,
				netProfit,
				salesHistoryDataset,
				costHistoryDataset,
				labels,
				name,
				setShowAddMetricModal,
				showAddMetricModal,
				salesDryHistory,
				salesWetHistory,
				salesDryCostHistory,
				salesWetCostHistory,
				payrollHistory,
				utilitiesHistory
			}}
		>
			{children}
		</MetricsDataContext.Provider>
	)
}
