import { Capitalise, isMobile } from '../common/utils'
import { LoginInputGroup, SubmitButtonGroup } from '../components/InputFields'
import { PasswordInput, textFontSize } from '../components/InputFields'
import React, { useEffect, useState } from 'react'

import LoginContainer from '../components/LoginContainer'
import { postSignUp } from '../common/methods'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

const Signup = () => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [name, setName] = useState('')
	const [business, setBusiness] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [invitationCode, setInvitationCode] = useState('')

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [renderErrorMessage, setRenderErrorMessage] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const navigate = useNavigate()
	const toast = useToast()

	useEffect(() => {
		document.title = 'Signup'
	}, [])

	const onChangeUsername = (e) => {
		setUsername(e.target.value.trim())
	}

	const onChangeBusiness = (e) => {
		setBusiness(e.target.value)
	}

	const onChangeName = (e) => {
		setName(e.target.value)
	}

	// const onChangeInvitationCode = (e) => {
	// 	setInvitationCode(e.target.value)
	// }

	const onSubmit = () => {
		if (confirmPassword === password) {
			setIsSubmitting(true)
			setRenderErrorMessage(false)
			const data = {
				email: username.trim(),
				name: name.trim(),
				password: password.trim(),
				business_name: business.trim(),
				// invitation_code: invitationCode.trim()
			}
			postSignUp({ data })
				.then(() => {
					navigate('/')
					toast({
						title: 'Account created!',
						description: "We've created your account for you.",
						status: 'success',
						duration: 3000,
						isClosable: true,
						position: 'top',
						containerStyle: {
							width: isMobile ? '300px' : '500px',
							marginTop: isMobile ? '20px' : '50px'
						}
					})
				})
				.catch((res) => {
					setIsSubmitting(false)
					setRenderErrorMessage(true)
					setErrorMessage(Capitalise(res.response.data.message))
				})
		} else {
			setRenderErrorMessage(true)
			setErrorMessage('Passwords dont match')
		}
	}

	return (
		<LoginContainer header="Signup">
			<LoginInputGroup
				value={name}
				onChange={onChangeName}
				placeholder="Name"
				title="Name"
			/>
			<LoginInputGroup
				value={business}
				onChange={onChangeBusiness}
				placeholder="Business Name"
				title="Business"
			/>
			<LoginInputGroup
				value={username}
				onChange={onChangeUsername}
				placeholder="Email"
				title="Email"
			/>
			<PasswordInput
				password={password}
				setPassword={setPassword}
				placeholder="Password"
				h="50px"
				fontSize={textFontSize}
				title="Password"
				tag="Password-Home"
			/>
			<PasswordInput
				password={confirmPassword}
				setPassword={setConfirmPassword}
				placeholder="Confirm Password"
				h="50px"
				fontSize={textFontSize}
				title="Confirm Password"
			/>
			{/* <LoginInputGroup
				value={invitationCode}
				onChange={onChangeInvitationCode}
				placeholder="Invitation Code"
				title="Invitation Code"
			/> */}
			<SubmitButtonGroup
				onSubmit={onSubmit}
				isSubmitting={isSubmitting}
				isErrored={renderErrorMessage}
				errorMessage={errorMessage}
			/>
		</LoginContainer>
	)
}

export default Signup
