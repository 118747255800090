import { Button, Flex, Text } from '@chakra-ui/react'

import React from 'react'
import { useNavigate } from 'react-router-dom'

const Error404 = () => {
	const navigate = useNavigate()
	const token = localStorage.getItem('user-token')
	document.title = 'Error 404'

	const navigateAway = () => {
		navigate(token ? '/profile' : '/login')
	}

	return (
		<Flex
			justifyContent={'space-around'}
			flexDirection="column"
			gap="10px"
			mx="auto"
			alignItems={'center'}
			mt="10rem"
		>
			<Text fontSize="5xl">404 Response</Text>
			<Text>The page you are looking for doesn't exist</Text>
			<Text>Please go to the {token ? 'profile page' : 'login page'}: .</Text>
			<Button onClick={navigateAway} width="8rem">
				{token ? 'Profile' : 'Login'}
			</Button>
		</Flex>
	)
}

export default Error404
