import { Suspense, createContext } from 'react'

import { Spinner } from '@chakra-ui/react'
import { getAccountInfo } from '../common/methods'
import { useEffect } from 'react'
import { useState } from 'react'

const initialState = {
	isLoading: true,
	accountInfo: {}
}

export const AccountDataContext = createContext(initialState)

export function AccountDataProvider({ children }) {
	const [isLoading, setIsLoading] = useState(true)
	const [showEditProfileModal, setShowEditProfileModal] = useState(false)
	const [accountInfo, setAccountInfo] = useState(null)
	const accessToken = localStorage.getItem('user-token')

	useEffect(() => {
		if (!showEditProfileModal) {
			getAccountInfo({ token: accessToken }).then((res) => {
				const { name, email, business_name } = res
				setAccountInfo({
					business_name,
					email,
					name
				})
				setIsLoading(false)
			})
		}
	}, [showEditProfileModal])

	return (
		<AccountDataContext.Provider
			value={{
				isLoading,
				accountInfo,
				showEditProfileModal,
				setShowEditProfileModal
			}}
		>
			{children}
		</AccountDataContext.Provider>
	)
}
